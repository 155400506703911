import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { tokenGetter } from '@app/helpers/auth.helper';

@Injectable({
  providedIn: 'root'
})
export class EventNotificationService {
  private hubConnection: signalR.HubConnection | undefined;
  private hubUrl = `${environment.api_url}entitynotifications/real-time`;
  private signalRMessage$ = new Subject<EventMessage>();
  signalRMessage = this.signalRMessage$.asObservable();

  public startConnection = () => {
    this.hubConnection = this.getConnection();
    this.hubConnection
      .start()
      .then(() => console.log(`Connected successfully to event notifications`))
      .catch(err => console.log(`Error while connecting to event notifications: ${err}`));
  }

  public startListener = () => {
    this.hubConnection?.on('receiveMessage', (eventMessage: EventMessage) => {
      this.signalRMessage$.next(eventMessage);
    })
  }

  private getConnection() {
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(this.hubUrl, {
    accessTokenFactory: () => {
      return tokenGetter() ?? ''
    }, withCredentials: false
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.None)
    .build();

    return connection;
  }

  public static parseMessage<T>(message: string): T {
    const parsedMessage = JSON.parse(message) as T;
    return parsedMessage;
  }
}

export interface EventMessage {
  message: string,
  messageKey: string
}
